<template>
<div>
    <vue-perfect-scrollbar class="chat-history--scrollbar" ref="chat_scroll" :settings="scrollbar_settings">
        <v-list two-line class="chat-history--list">
            <template v-for="(item, index) in comments">
                <v-list-item class="chat-list" v-bind:class="{ 'light-green lighten-5': (index == comments.length - 1 && item.UserName != myName && todayMessage(item.CreatedDate)), 'sms-private': (item.Type === 2) }" :key="item.ID">
                    <v-list-item-avatar v-if="item.UserName != myName">
                        <img :src="item.RoleType | comment_avatar">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle v-bind:class="{ 'sms-align-right': item.UserName == myName}" v-html="getTitle(item.UserName ?  item.UserName : '' + item.CustomerName ? item.CustomerName : '', item.CreatedDate)"></v-list-item-subtitle>
                        <p v-bind:class="{ 'sms-align-right': item.UserName == myName}">{{ item.Comment }}</p>
                    </v-list-item-content>
                    <v-list-item-avatar v-if="item.UserName == myName">
                        <img :src="item.RoleType | comment_avatar">
                    </v-list-item-avatar>
                </v-list-item>
                <v-divider :key="`divider-${index}`" v-if="index < comments.length - 1"></v-divider>
            </template>
        </v-list>
    </vue-perfect-scrollbar>
    <v-divider></v-divider>
    <v-layout row class="pt-2">
        <v-select class="sms-dest" label="Khu vực gửi tin" v-model="selectedArea" :items="areas" item-text="name" item-value="value"></v-select>
        <v-text-field class="sms-input" append-icon="fa-paper-plane" v-model="message" label="Nhập tin nhắn cho đơn hàng" @click:append="onSendMessage" v-on:keyup.enter="onSendMessage">
            <v-icon slot="append-icon">fa-paper-plane</v-icon>
        </v-text-field>
    </v-layout>
</div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import moment from "moment"
import configs from '@/configs'
import _ from "lodash";
import {
    mapState
} from "vuex";
import {
    comment_avatar,
    comment_type,
    comment_type_color,
} from "@/commons/hpod";

export default {
    name: 'message-box',
    filters: {
        comment_type,
        comment_type_color,
        comment_avatar,
    },
    components: {
        VuePerfectScrollbar
    },
    props: {
        orderId: {
            type: Number,
            default: 0
        },
        orderType: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            message: "",
            timer: "",
            selectedArea: 1,
            scrollbar_settings: {
               wheelPropagation: true
            }
        }
    },
    computed: {
        ...mapState("orderComment", {
            comments: "all"
        }),
        myName() {
            return this.$store.state.authentication.user.username;
        },
        storeVNRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN;
        },
        storeTQRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ;
        },
        areas() {
            return this.storeVNRole || this.storeTQRole ? _.find(configs.COMMENT_TYPE, {value: 2}) : configs.COMMENT_TYPE;
        }
    },
    watch: {
        orderId() {
            this.fetchData();
        },
        comments() {
            this.scrollToEnd();
        }
    },
    methods: {
        todayMessage(createTime) {
            return moment(createTime).startOf('day').isSame(moment().startOf('day'));
        },
        scrollToEnd() {
            setTimeout(() => {
                const container = this.$el.querySelector('.ps-container');
                container.scrollTop = container.scrollHeight;
            }, 0);
        },
        fetchData: _.debounce(function () {
            this.$store.dispatch("orderComment/getOrderCommentList", {id: this.orderId, type: this.orderType});
        }, 1000, {
            'leading': true,
            'trailing': false
        }),
        onSendMessage() {
            if(this.message != "") {
                this.$store.dispatch("orderComment/putOrderComment", {
                    'OrderID': this.orderId,
                    'Comment': this.message,
                    'Status': 0,
                    'Type': this.selectedArea,
                    'OrderType': this.orderType !== '' ? this.orderType : null,
                    'UserID': this.$store.state.authentication.user.userid
                });
                this.message = "";
                this.scrollToEnd();
            }
        },
        getTitle(user_name, send_time) {
            return "<span class='text--primary font-weight-bold'>"+user_name+"</span>: " + moment(send_time).format("DD-MM-YYYY HH:mm:ss")
        }
    },
    mounted() {
        this.selectedArea = this.storeVNRole || this.storeTQRole ? 2 : 1;
        this.fetchData();
    },
    created() {
        this.timer = setInterval(this.fetchData, 1000*configs.SYSTEM_CONFIG.notification_time);
    },
    destroyed() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.v-list--two-line .v-list__tile {
    height: auto !important;
    padding: 5px 0 5px 0;
}

.sms-dest {
    width: 20vw;
    margin-left: 12px;
}

.sms-input {
    width: 80vw;
    margin-left: 8px;
    margin-right: 12px;
}

.sms-align-right {
    text-align: right;
}

.sms-private {
    background-color: #FFDDDD
}
</style>
